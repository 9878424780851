:root {
  --background-color: #fff;
  --text-color: #333;
  --accent-color: #007bff;
  --border-color: #e0e0e0;
  --control-height: 60px;
}

html, body {
  touch-action: manipulation;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

body {
  background-color: var(--background-color);
  color: var(--text-color);
  font-family: Segoe UI, Roboto, Helvetica Neue, sans-serif;
}

.site-container {
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
}

.canvas-container {
  background-color: var(--background-color);
  border: 1px solid var(--border-color);
  width: 95%;
  height: 95%;
  position: relative;
  overflow: hidden;
}

.canvas-controls, .bottom-controls {
  height: var(--control-height);
  box-sizing: border-box;
  z-index: 10;
  background-color: #fffc;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 20px;
  font-size: 16px;
  display: flex;
  position: absolute;
}

.canvas-controls {
  top: 0;
}

.bottom-controls {
  bottom: 0;
}

.canvas {
  top: var(--control-height);
  bottom: var(--control-height);
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  left: 0;
  right: 0;
}

.additional-controls {
  border-radius: 5px;
  flex-direction: column;
  align-items: center;
  display: flex;
  position: absolute;
  bottom: 50%;
  right: 10px;
  transform: translateY(50%);
}

.control-btn, .main-control-btn {
  color: var(--text-color);
  cursor: pointer;
  background-color: #0000;
  border: none;
  padding: 10px 15px;
  font-size: 16px;
  transition: color .3s;
}

.control-btn:hover, .main-control-btn:hover {
  color: var(--accent-color);
}

.main-control-btn.active-button {
  background-color: var(--accent-color);
  color: #fff;
  border-radius: 5px;
  transition: background-color .3s, color .3s;
  box-shadow: 0 4px 6px #0000001a;
}

.canvas {
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
}

.main-controls {
  justify-content: flex-end;
  gap: 15px;
  width: 100%;
  display: flex;
}

.additional-controls {
  border-radius: 5px;
  flex-direction: column;
  align-items: center;
  width: 75px;
  display: flex;
  position: absolute;
  bottom: 40%;
  right: 0;
  transform: translateY(-50%);
}

.slider-label {
  position: absolute;
}

.top-label {
  top: -80px;
}

.bottom-label {
  bottom: -75px;
}

.zoom-slider {
  -webkit-appearance: none;
  transform-origin: center;
  width: 150px;
  height: 0;
  margin: 20px 0;
  transform: rotate(-90deg);
}

.zoom-slider::-webkit-slider-runnable-track {
  background: var(--border-color);
  border-radius: 2px;
  width: 100%;
  height: 4px;
}

.zoom-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  background: var(--accent-color);
  cursor: pointer;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  transition: background .3s;
  position: relative;
  top: -8px;
}

.zoom-slider:hover::-webkit-slider-thumb {
  background: #0056b3;
}

.zoom-slider::-moz-range-track {
  background: var(--border-color);
  border-radius: 2px;
  width: 100%;
  height: 4px;
}

.zoom-slider::-moz-range-thumb {
  background: var(--accent-color);
  cursor: pointer;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  transition: background .3s;
}

.zoom-slider:hover::-moz-range-thumb {
  background: #0056b3;
}

.modal {
  z-index: 1000;
  background-color: #00000080;
  width: 100%;
  height: 100%;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  overflow: auto;
}

.modal-content {
  background-color: #fefefe;
  border: 1px solid #888;
  border-radius: 8px;
  width: 80%;
  max-width: 800px;
  margin: 5% auto;
  padding: 20px;
  position: relative;
  box-shadow: 0 5px 15px #0000004d;
}

.modal-close {
  color: #aaa;
  float: right;
  cursor: pointer;
  font-size: 28px;
  font-weight: bold;
  position: absolute;
  top: 10px;
  right: 20px;
}

.modal-close:hover, .modal-close:focus {
  color: #000;
  text-decoration: none;
}

@keyframes modalFadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.modal-content {
  animation-name: modalFadeIn;
  animation-duration: .4s;
}
/*# sourceMappingURL=index.eccf092d.css.map */
