:root {
  --background-color: #ffffff;
  --text-color: #333333;
  --accent-color: #007bff;
  --border-color: #e0e0e0;
  --control-height: 60px; /* Adjust this value to match the actual height of your controls */
}

html,
body {
  margin: 0;
  padding: 0;
  height: 100%; /* Ensures the body fills the viewport height */
  overflow: hidden; /* Prevents scrolling */
  touch-action: manipulation;
}

body {
  font-family: "Segoe UI", "Roboto", "Helvetica Neue", sans-serif;
  background-color: var(--background-color);
  color: var(--text-color);
}

.site-container {
  width: 100%;
  height: 100%; /* Fills the viewport */
  display: flex;
  justify-content: center;
  align-items: center;
}

.canvas-container {
  width: 95%;
  height: 95%;
  position: relative; /* Necessary for absolute positioning of children */
  background-color: var(--background-color);
  border: 1px solid var(--border-color);
  overflow: hidden;
}

.canvas-controls,
.bottom-controls {
  position: absolute; /* Keeps controls fixed at top and bottom */
  width: 100%;
  height: var(--control-height); /* Consistent control height */
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  box-sizing: border-box;
  z-index: 10;
  font-size: 16px;
  background-color: rgba(255, 255, 255, 0.8);
}

.canvas-controls {
  top: 0; /* Positions at the top */
}

.bottom-controls {
  bottom: 0; /* Positions at the bottom */
}

.canvas {
  position: absolute;
  top: var(--control-height); /* Starts below top controls */
  bottom: var(--control-height); /* Ends above bottom controls */
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.additional-controls {
  position: absolute;
  bottom: 50%; /* Centers vertically within the canvas */
  right: 10px; /* Adjust as needed */
  transform: translateY(50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 5px;
}

.control-btn,
.main-control-btn {
  background-color: transparent;
  color: var(--text-color);
  border: none;
  padding: 10px 15px;
  cursor: pointer;
  transition: color 0.3s;
  font-size: 16px;
}

.control-btn:hover,
.main-control-btn:hover {
  color: var(--accent-color);
}

.main-control-btn.active-button {
  background-color: var(--accent-color);
  color: #ffffff;
  border-radius: 5px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s, color 0.3s;
}

.canvas {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.main-controls {
  display: flex;
  gap: 15px;
  justify-content: flex-end;
  width: 100%;
}

.additional-controls {
  position: absolute;
  width: 75px;
  bottom: 40%;
  right: 0px;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 5px;
}

.slider-label {
  position: absolute;
}

.top-label {
  top: -80px; /* Adjust as needed */
}

.bottom-label {
  bottom: -75px; /* Adjust as needed */
}

/* Existing styles for the slider */
.zoom-slider {
  -webkit-appearance: none;
  width: 150px;
  height: 0px;
  margin: 20px 0;
  transform: rotate(-90deg);
  transform-origin: center center;
}

/* Customize the slider track for WebKit browsers */
.zoom-slider::-webkit-slider-runnable-track {
  width: 100%;
  height: 4px;
  background: var(--border-color);
  border-radius: 2px;
}

/* Customize the slider thumb for WebKit browsers */
.zoom-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 20px;
  height: 20px;
  background: var(--accent-color);
  border-radius: 50%;
  cursor: pointer;
  transition: background 0.3s;
  position: relative;
  top: -8px; /* Adjusted position */
}

/* Hover effect for the thumb in WebKit browsers */
.zoom-slider:hover::-webkit-slider-thumb {
  background: #0056b3;
}

/* Existing styles for Firefox remain unchanged */
/* Customize the slider track for Firefox */
.zoom-slider::-moz-range-track {
  width: 100%;
  height: 4px;
  background: var(--border-color);
  border-radius: 2px;
}

/* Customize the slider thumb for Firefox */
.zoom-slider::-moz-range-thumb {
  width: 20px;
  height: 20px;
  background: var(--accent-color);
  border-radius: 50%;
  cursor: pointer;
  transition: background 0.3s;
}

/* Hover effect for the thumb in Firefox */
.zoom-slider:hover::-moz-range-thumb {
  background: #0056b3;
}

/* Modal Styles */
.modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1000; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgba(0, 0, 0, 0.5); /* Black w/ opacity */
}

.modal-content {
  background-color: #fefefe;
  margin: 5% auto; /* 5% from the top and centered */
  padding: 20px;
  border: 1px solid #888;
  width: 80%; /* Could be more or less, depending on screen size */
  max-width: 800px; /* Maximum width */
  border-radius: 8px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  position: relative;
}

.modal-close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
  position: absolute;
  top: 10px;
  right: 20px;
  cursor: pointer;
}

.modal-close:hover,
.modal-close:focus {
  color: black;
  text-decoration: none;
}

/* Optional: Add animation for modal appearance */
@keyframes modalFadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.modal-content {
  animation-name: modalFadeIn;
  animation-duration: 0.4s;
}
